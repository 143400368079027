@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap');


.tech-illustration {
  width: 100%;
  height: 400px;
  /* Adjust the height to match the image */
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0) 45%, #E0E4E8 75%),
    url('./assets/images/hero1.png') center/cover no-repeat;
  opacity: 1;
  z-index: 1;
}

.case-study-tile {
  transition: transform 0.3s, box-shadow 0.3s;
}

.case-study-tile:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.case-study-tile {
  cursor: pointer;
}

.clip-diagonal {
  position: relative;
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 90%);
  border-radius: 50px; /* Adjust for rounded corners */
  background: linear-gradient(to right, #1e3a8a, #3b82f6); /* Match your gradient */
}

.fill-current {
  background: transparent; /* Ensure no background fills the SVG container */
}